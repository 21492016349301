@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto+Slab:wght@700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
:root {
  --main-color: #181818;
  --seccond-color: rgb(228, 228, 228);
  --black: 15, 18, 25;
  --purple: #2e016c;
  --dark-purple: rgb(11 0 37);
  --purple-bg: #19003c;
  --purple-buble: #ae00ff;
  --ligth-purple: #610094;
  --blue: #58ecff;
  --accent: #2337ff;
  --accent-dark: #000d8a;
  --gray: rgb(96, 115, 159);
  --gray-light: rgb(229, 233, 240);
  --gray-dark: rgb(34, 41, 57);
  --gray-gradient: rgba(var(--gray-light), 50%), #fff;
  --box-shadow: 0 2px 6px rgba(var(--gray), 25%),
    0 8px 24px rgba(var(--gray), 33%), 0 16px 32px rgba(var(--gray), 33%);
  --box-shadow-toggle: 2px 1px 20px 0px rgba(145, 192, 255, 0.5),
    inset 2px 0px 1px 0px rgba(214, 232, 255, 0.214),
    inset 0px 11px 28px 0px rgb(255, 255, 255);
  --gradient: 181deg, #000000 35%, #2e006e 100%;
  --gradient-transparent: 181deg, #2e006e 50%, #000000c5 100%;
  --green-terminal: rgb(0, 255, 102);
  --yellow-terminal: rgb(182, 182, 3);
  --pink-terminal: rgb(255, 0, 144);
  --blue-terminal: aqua;
  --fira-code: "Fira Code", monospace;
  --montserrat: "Montserrat", sans-serif;
  --pure-black: #000000;
  --terminal-bg-dark: rgba(18, 0, 33, 0.734);
  --icons-glow-dark: invert(1) saturate(0%) sepia(0) var(--glow-blue);
  --glow-blue: drop-shadow(0 0px 5px rgba(0, 230, 270, 2));

  --font-robot: "Roboto", sans-serif;
  --font-montserrat: "Montserrat", sans-serif;

  --header-font-size: 3.5rem;
  --description-font-size: 1.3rem;
  --subheading-font-size: 2rem;
  --header-tab-font-size: 2.8rem;
  --description-tab-font-size: 1rem;
  --subheading-tab-font-size: 1.6rem;
  --header-mobile-font-size: 2rem;
  --description-mobile-font-size: 0.6rem;
  --subheading-mobile-font-size: 1.2rem;
}
@media (max-width: 1080px) {
  :root {
    --header-font-size: 2.8rem;
    --description-font-size: 1.2rem;
    --subheading-font-size: 1.6rem;
  }
}
@media (max-width: 786px) {
  :root {
    --header-font-size: 2rem;
    --description-font-size: 1.1rem;
    --subheading-font-size: 1.5rem;
  }
}
@media (max-width: 480px) {
  :root {
    --header-font-size: 2rem;
    --description-font-size: 1rem;
    --subheading-font-size: 1.3rem;
  }
}
* {
  scroll-behavior: smooth;
  font-size: 16px;
  user-select: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}

div,
p {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: fit-content;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
