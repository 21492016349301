.header-container {
  box-sizing: border-box;
  background-color: black;
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding: 0 4rem;
  position: fixed;
  z-index: 10000;
  width: 100vw;
  opacity: 80%;
}
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  display: inline;
  font-size: var(--subheading-font-size);
  justify-self: start;
  color: #fcedd8;
  font-family: "Niconne", cursive;
  font-weight: 700;
  text-shadow: 1px 1px 0px var(--accent-dark),
    1.5px 1.5px 0px var(--green-terminal), 2px 2px 0px #46b59b,
    2.2px 2.2px 0px var(--yellow-terminal), 2.5px 2.5px 0px var(--pink-terminal),
    3px 3px 0px var(--blue-terminal);
}
.nav-bar-list {
  display: flex;
  gap: 2rem;
  padding: 1.5rem;
  color: var(--seccond-color);
  font-weight: 500;
  text-shadow: -0.1rem 0rem 0rem var(--main-color);
  justify-self: end;
}
.navbar-link {
  font-size: var(--description-font-size);
}
.nav-bar-list > li {
  transition: transform 0.3s ease-in-out;
}
.nav-bar-list > li:hover {
  cursor: pointer;
  color: var(--blue);
  transform: scale(1.1);
}

.hamburger-menu-div {
  justify-self: flex-end;
}
.hamburger-icon-off {
  opacity: 0;
}
.hamburder-menu-list {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #00000b;
  right: 0px;
  top: 0px;
}
.hamburder-menu-item {
  color: var(--seccond-color);
  padding: 1rem 3rem;
  border-bottom: 2px solid #fff;
}
.hamburger-close-icon-div {
  display: flex;
  justify-content: center;
}
.hamburger-menu-close {
  height: 48px;
}
@media (min-width: 760px) {
  .hamburger-menu-div {
    display: none;
  }
}
@media (max-width: 759px) {
  .nav-bar-list {
    display: none;
  }
}
@media (max-width: 600px) {
  .hamburder-menu-item {
    padding: 1rem 5rem;
  }
}
