.skill-container {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding-left: 6rem;
  align-items: center;
  overflow-x: hidden;
}
.skill-text {
  padding: 6rem 2rem 6rem 6rem;
  box-shadow: 3px 25px 15px -23px var(--accent-dark) inset,
    14px -5px 9px -21px var(--accent-dark), 15px 0 18px -20px var(--accent-dark);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.skill-text h1 {
  padding: 0;
  font-size: var(--header-font-size);
}
.skill-carousel {
  width: inherit;
}

@media (max-width: 700px) {
  .skill-container {
    margin: 2rem 0;
    grid-template-columns: 1fr;
    padding-left: 0rem;
  }
  .skill-text {
    padding: 0 0 2rem 2rem;
    box-shadow: none;
  }
  .skill-carousel {
    padding-bottom: 3rem;
  }
  .carousel-svg {
    height: 4rem;
    width: 4rem;
  }
}
