.hero-container {
  position: relative;
  transition: 0.9s ease;
  display: flex;
  min-height: 120vh;
  background: linear-gradient(var(--gradient));
  padding-bottom: 15rem;
}
.curve-svg-hero {
  position: absolute;
  bottom: 0;
}
.hero-main-content {
  color: var(--seccond-color);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
  justify-items: space-evenly;
}
.main-content-intro {
  font-family: Montserrat, sans-serif;
  transition: all 0.9s;
  color: var(--seccond-color);
  padding: 0 2rem;
  text-align: center;
  font-weight: 700;
  filter: drop-shadow(2 -1px 1px var(--pure-black));
  line-height: initial;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.intro-span {
  font-size: var(--header-font-size);
}
.intro-span:hover {
  color: var(--blue);
  filter: drop-shadow(0 0 12px var(--blue));
}
.intro-h2 {
  font-size: var(--subheading-font-size);
  color: var(--pink-terminal);
}
.intro-h2-gt {
  color: var(--green-terminal);
}
.intro-name {
  animation: flickering 2s linear 0.5s both;
}
.resume-btn {
  font-size: var(--description-font-size);
  margin-top: 20px;
  padding: 0.8rem 2rem;
  width: fit-content;
  background: #000;
  box-shadow: 0 8px 32px #1f26875e;
  border-radius: 9px;
  border: 1.5px solid var(--green-terminal);
  font-weight: 700;
  cursor: pointer;
  align-self: center;
  color: #fff;
}
.resume-btn:hover {
  border: 1.8px solid var(--blue);
}
.main-content-icons {
  position: relative;
}
.user-photo {
  height: 35rem;
  width: auto;
  opacity: 0;
  animation: appear 1s linear 1s forwards;
}
.social {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  z-index: 80;
}
.social_icon {
  position: absolute;
  width: 68px;
  height: 68px;
  filter: drop-shadow(0 0 12px var(--seccond-color));
  animation: anim 2s infinite;
  animation-delay: 0.9s;
}
.social_icon:hover {
  animation: none;
  transform: scale(1.3);
}

.social__link {
  position: absolute;
}

@keyframes anim {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-9px);
  }
  100% {
    transform: translateY(0);
  }
}

.icon-1 {
  top: 150px;
  left: 110px;
  transform: translateY(-200px);
  animation: slideIn 1s ease-in-out forwards;
}
.icon-2 {
  bottom: 150px;
  left: 110px;
  transform: translateY(-600px);
  animation: expand 0.8s ease forwards;
}
.icon-3 {
  top: 150px;
  right: 250px;
  transform: translateY(-300px);
  animation: expand 0.8s ease forwards;
}
.icon-4 {
  bottom: 150px;
  right: 200px;
  transform: translateY(500px);
  animation: expand 0.6s ease-in-out forwards 0.8s;
}

@keyframes slideIn {
  0% {
    transform: translateY(600px) scale(9);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

@keyframes expand {
  0% {
    transform: translateY(1400px) scale(5);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}
@keyframes appear {
  100% {
    opacity: 1;
  }
}
@keyframes flickering {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  10.1% {
    opacity: 1;
  }
  10.2% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  20.1% {
    opacity: 1;
    color: var(--glow-blue);
    filter: drop-shadow(0 0 12px var(--blue));
  }
  20.6% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  30.1% {
    opacity: 1;
    color: var(--glow-blue);
    filter: drop-shadow(0 0 12px var(--blue));
  }
  30.5% {
    opacity: 1;
    color: var(--glow-blue);
    filter: drop-shadow(0 0 12px var(--blue));
  }
  30.6% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  45.1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    color: var(--glow-blue);
    filter: drop-shadow(0 0 12px var(--blue));
  }
  55% {
    opacity: 1;
    color: var(--glow-blue);
    filter: drop-shadow(0 0 12px var(--blue));
  }
  55.1% {
    opacity: 0;
    color: var(--glow-blue);
    filter: drop-shadow(0 0 12px var(--blue));
  }
  57% {
    opacity: 0;
  }
  57.1% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  60.1% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  65.1% {
    opacity: 1;
    color: var(--glow-blue);
    filter: drop-shadow(0 0 12px var(--blue));
  }
  75% {
    opacity: 1;
  }
  75.1% {
    opacity: 0;
  }
  77% {
    opacity: 0;
  }
  77.1% {
    opacity: 1;
    color: var(--glow-blue);
    filter: drop-shadow(0 0 12px var(--blue));
  }
  85% {
    opacity: 1;
  }
  85.1% {
    opacity: 0;
    color: var(--glow-blue);
    filter: drop-shadow(0 0 12px var(--blue));
  }
  86% {
    opacity: 0;
  }

  86.1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 950px) {
  .user-photo {
    height: 30rem;
  }
  .social_icon {
    width: 54px;
    height: 54px;
  }
  .icon-3 {
    right: 180px;
  }
  .icon-4 {
    right: 170px;
  }
}
@media (max-width: 820px) {
  .hero-container {
    padding-bottom: 5rem;
  }
  .user-photo {
    height: 25rem;
  }
  .social_icon {
    width: 42px;
    height: 42px;
  }
  .icon-1 {
    left: 90px;
  }
  .icon-2 {
    left: 100px;
  }
  .icon-3 {
    right: 160px;
  }
  .icon-4 {
    right: 150px;
  }
}
@media (max-width: 700px) {
  .hero-main-content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    height: 100vh;
  }
  .main-content-intro {
    order: 2;
  }
  .main-content-icons {
    order: 1;
    text-align: center;
  }
  .icon-1 {
    left: 190px;
  }
  .icon-2 {
    left: 180px;
    bottom: 110px;
  }
  .icon-3 {
    right: 250px;
  }
  .icon-4 {
    right: 240px;
    bottom: 110px;
  }
}
@media (max-width: 640px) {
  .icon-1 {
    left: 170px;
  }
  .icon-2 {
    left: 160px;
  }
  .icon-3 {
    right: 230px;
  }
  .icon-4 {
    right: 220px;
  }
}

@media (max-width: 580px) {
  .icon-1 {
    left: 140px;
  }
  .icon-2 {
    left: 130px;
  }
  .icon-3 {
    right: 200px;
  }
  .icon-4 {
    right: 190px;
  }
}

@media (max-width: 540px) {
  .icon-1 {
    left: 110px;
  }
  .icon-2 {
    left: 100px;
  }
  .icon-3 {
    right: 160px;
  }
  .icon-4 {
    right: 150px;
  }
}

@media (max-width: 450px) {
  .user-photo {
    height: 20rem;
  }
  .social_icon {
    width: 36px;
    height: 36px;
  }
  .icon-1 {
    left: 80px;
  }
  .icon-2 {
    left: 70px;
    bottom: 90px;
  }
  .icon-3 {
    right: 140px;
  }
  .icon-4 {
    right: 130px;
    bottom: 90px;
  }
}

@media (max-width: 359px) {
  .user-photo {
    height: 18rem;
  }
  .social_icon {
    width: 30px;
    height: 30px;
  }
  .icon-1 {
    left: 80px;
  }
  .icon-2 {
    left: 70px;
    bottom: 70px;
  }
  .icon-3 {
    right: 120px;
  }
  .icon-4 {
    right: 110px;
    bottom: 70px;
  }
}
