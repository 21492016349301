.overview-container {
  padding: 2rem 4rem 4rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.overview-intro {
  width: 40vw;
}
.overview-intro > h1 {
  font-size: var(--header-font-size);
  margin-bottom: 1.5rem;
}

.overview-intro > p {
  font-size: var(--description-font-size);
  font-weight: 500;
}

.rotated-curve {
  transform: rotate(180deg);
}
.overview-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem;
}

@media (max-width: 950px) {
  .overview-intro {
    width: 50vw;
  }
  .overview-cards {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1frs);
    column-gap: 10rem;
  }
}
@media (max-width: 680px) {
  .overview-cards {
    column-gap: 2rem;
  }
}
@media (max-width: 490px) {
  .overview-cards {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1frs);
  }
}
