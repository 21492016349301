.gradient-border {
  background: linear-gradient(181deg, #2e006e 50%, #000000c5 100%);
  border-radius: 15px;
  padding: 3px;
  transition: transform 0.6s ease;
}
.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 4rem 2rem;
  border: 1px solid transparent;
  border-radius: 15px;
  height: 13rem;
  transition: transform 0.6s ease;
  background: radial-gradient(
    circle,
    rgba(255, 255, 253, 1) 0%,
    rgba(222, 255, 255, 1) 100%
  );
}
.card-container div {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}
.card-container div h1 {
  font-size: var(--subheading-font-size);
  text-align: center;
}
.card-svg {
  height: 68px;
  width: 68px;
  animation: diagonal-motion-1 4s infinite ease-in-out;
}
@keyframes diagonal-motion-1 {
  0% {
    transform: translateX(-3px) translateY(-3px);
  }
  25% {
    transform: translateX(0px) translateY(0px);
  }
  50% {
    transform: translateX(3px) translateY(-3px);
  }
  75% {
    transform: translateX(0px) translateY(-6px);
  }
  100% {
    transform: translateX(-3px) translateY(-3px);
  }
}
.card-desc {
  font-size: 1px;
  text-align: center;
  opacity: 0;
  transition: font-size 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.gradient-border:hover {
  transform: scale(1.05);
}
.gradient-border:hover .card-container {
  background: transparent;
  color: #fff;
}
.gradient-border:hover .card-container .card-desc {
  opacity: 100;
  font-size: var(--description-font-size);
  font-weight: 700;
}
.gradient-border:hover .card-container div {
  display: none;
}
.gradient-border:hover .card-container div .card-svg {
  animation: none;
}

@media (max-width: 1080px) {
  .card-container {
    height: 10rem;
  }
}
@media (max-width: 500px) {
  .card-container {
    padding: 2rem 1rem;
  }
}
