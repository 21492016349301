.experience-container {
  position: relative;
  background-color: var(--dark-purple);
  padding-bottom: 8rem;
}
.experience-container > h1 {
  color: #fff;
  font-size: var(--header-font-size);
  text-align: center;
  padding-bottom: 4rem;
}
.curve-svg-experience {
  position: absolute;
  bottom: 0;
}
