.contact-main-div {
  background-color: var(--dark-purple);
  padding-bottom: 10rem;
}
.contact-h1 {
  font-size: var(--header-font-size);
  color: #fff;
  text-align: center;
}
.contact-container {
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  gap: 5rem;
  align-items: center;
}
.form-container {
  padding-left: 4rem;
}
.email-success-div {
  padding-top: 2rem;
}
.contact-img {
  height: 30rem;
  align-self: center;
}

@media (max-width: 950px) {
  .contact-img {
    height: 25rem;
  }
}

@media (max-width: 860px) {
  .contact-img {
    height: 22rem;
  }
}
@media (max-width: 815px) {
  .contact-img {
    height: 20rem;
  }
}
@media (max-width: 815px) {
  .contact-container {
    grid-template-columns: 1fr;
  }
  .form-container {
    padding-right: 4rem;
  }
  .contact-img {
    display: none;
  }
}
